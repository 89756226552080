import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import socialIcon from "src/assets/images/social-module.svg"
import shopIcon from "src/assets/images/shop-module.svg"
import restaurantIcon from "src/assets/images/restaurant-module.svg"
import hotelIcon from "src/assets/images/hotel-module.svg"
import travelIcon from "src/assets/images/travel-module.svg"
import carIcon from "src/assets/images/car-module.svg"

export const MODULE_TYPE = {
  SOCIAL: "SOCIAL",
  SHOP: "SHOP",
  RESTAURANT: "RESTAURANT",
  HOTEL: "HOTEL",
  TRAVEL: "TRAVEL",
  CAR: "CAR",
}
export default function HomePage() {
  const { t } = useTranslation()

  const moduleData = useMemo(() => {
    return [
      {
        icon: socialIcon,
        label: capitalizedFirst(t("home-page.social-module")),
        type: MODULE_TYPE.SOCIAL,
      },
      {
        icon: shopIcon,
        label: capitalizedFirst(t("home-page.shop-module")),
        type: MODULE_TYPE.SHOP,
        isHaveData: false,
      },
      {
        icon: restaurantIcon,
        label: capitalizedFirst(t("home-page.restaurant-module")),
        type: MODULE_TYPE.RESTAURANT,
        isHaveData: true,
      },
      {
        icon: hotelIcon,
        label: capitalizedFirst(t("home-page.hotel-module")),
        type: MODULE_TYPE.HOTEL,
      },
      {
        icon: travelIcon,
        label: capitalizedFirst(t("home-page.travel-module")),
        type: MODULE_TYPE.TRAVEL,
      },
      {
        icon: carIcon,
        label: capitalizedFirst(t("home-page.car-module")),
        type: MODULE_TYPE.CAR,
      },
    ]
  }, [])

  const handleClickModule = (type: string, haveData: boolean) => {
    let urlRedirect = ""
    switch (type) {
    case MODULE_TYPE.RESTAURANT:
      urlRedirect = haveData
        ? `${process.env.REACT_APP_FOOD_MANAGEMENT_DOMAIN_URL}`
        : `${process.env.REACT_APP_FOOD_DOMAIN_URL}/request-open-restaurant`
      break
    case MODULE_TYPE.HOTEL:
      urlRedirect = `${process.env.REACT_APP_HOTEL_BETA_DOMAIN_URL}`
      break
    case MODULE_TYPE.SHOP:
      urlRedirect = haveData
        ? `${process.env.REACT_APP_ECOMMERCE_MANAGEMENT_DOMAIN_URL}`
        : `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/request-open-shop`
      break
    case MODULE_TYPE.SOCIAL:
      urlRedirect = `${process.env.REACT_APP_HOME_DOMAIN_URL}`
      break
    case MODULE_TYPE.CAR:
      urlRedirect = `${process.env.REACT_APP_BOOKING_CAR_BETA_DOMAIN_URL}`
      break
    case MODULE_TYPE.TRAVEL:
      urlRedirect = `${process.env.REACT_APP_TOUR_BETA_DOMAIN_URL}`
      break
    default:
      break
    }
    if (urlRedirect) window.open(urlRedirect, "_self")
  }
  const getStyleHaveData = (type: string, value: boolean) => {
    if (type === MODULE_TYPE.SHOP || type === MODULE_TYPE.RESTAURANT) {
      return !value ? "opacity-50" : ""
    }
    return ""
  }

  return (
    <div className="mt-[96px] flex h-full w-full flex-col items-center justify-center rounded-3 bg-white py-2">
      <div className="flex w-full md:w-[1200px] max-w-[1200px] flex-col">
        <span className="text-center text-[20px] font-medium leading-[30px] text-gray-900">
          {capitalizedFirst(t("home-page.select-module"))}
        </span>
        <div className="mt-[48px] grid md:grid-cols-6 grid-cols-2 gap-3">
          {moduleData.map((i) => (
            <div
              onClick={() => handleClickModule(i.type, !!i?.isHaveData)}
              key={i.type}
              className={`flex flex-1 cursor-pointer flex-col items-center justify-center rounded-3 py-[40px] text-gray-700 hover:bg-blue-600 hover:text-white ${getStyleHaveData(
                i.type,
                !!i?.isHaveData
              )}`}
            >
              <div className="flex h-[72px] w-[72px] items-center justify-center rounded-full bg-white">
                <img className="h-[56px] w-[56px]" src={i.icon} alt={i.label} />
              </div>
              <span className="mt-[20px] text-[16px] font-medium leading-[24px]">
                {i.label}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
