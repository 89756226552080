import React, { useEffect, useRef, useState } from "react"
import "./App.scss"
import MainLayout from "./components/layout/main-layout"
import { Navigate, useRoutes } from "react-router-dom"
import { Toast } from "primereact/toast"
import { ToastContext } from "./app/toast-context"
import { ROUTERS } from "./constants/router"
import { STATE_RENDER_APP } from "./constants/common"
import PageNotFound from "./pages/404"
import ShareToken from "./components/share-token"
import HomePage from "./pages/home-page"
import { useSelector } from "react-redux"
import type { RootState } from "src/app/store"

function App() {
  const authStore = useSelector((state: RootState) => state.auth)
  const routes = useRoutes([
    {
      path: ROUTERS.HOME,
      element: <HomePage />,
    },
    {
      path: ROUTERS.PAGE_NOT_FOUND,
      element: <PageNotFound />,
    },
    {
      path: "/",
      element: <Navigate to={ROUTERS.HOME} />,
    },
    { path: "*", element: <Navigate to={ROUTERS.PAGE_NOT_FOUND} /> },
  ])

  const [stateRenderView, setStateRenderView] = useState<string>(
    STATE_RENDER_APP.INIT
  )
  const clearStorage = () => {
    Object.keys(localStorage).map((i) => {
      if (i !== "lang") localStorage.removeItem(i)
    })
  }
  const detectLogin = () => {
    setStateRenderView(STATE_RENDER_APP.IFRAME)
  }
  useEffect(() => {
    function initView() {
      clearStorage()
      detectLogin()
    }
    initView()
  }, [])

  useEffect(() => {
    if (stateRenderView === STATE_RENDER_APP.APP && !authStore.value) {
      window.open(`${process.env.REACT_APP_HOME_DOMAIN_URL}/login`, "_self")
    }
  }, [stateRenderView, authStore])

  const toast = useRef<Toast>(null)
  if (stateRenderView === STATE_RENDER_APP.INIT) return null
  if (stateRenderView === STATE_RENDER_APP.IFRAME)
    return (
      <ShareToken
        setStateRenderView={(newValue: string) => setStateRenderView(newValue)}
      />
    )
  return (
    <ToastContext.Provider value={toast}>
      {stateRenderView === STATE_RENDER_APP.APP ? (
        <MainLayout onSearch={(keyword) => console.log("searching", keyword)}>
          {routes}
        </MainLayout>
      ) : null}
      <Toast ref={toast} />
    </ToastContext.Provider>
  )
}

export default App
