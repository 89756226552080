import React from "react"
import "./main-layout.scss"
import type { MainLayoutProps } from "./main-layout-props"
import HeaderView from "../header-view"
import logoApp from "src/assets/images/logo.svg"
import { isDevMode } from "src/utils/app-logo"
export type { MainLayoutProps }

export default function MainLayout(props: MainLayoutProps) {
  return (
    <div
      className="no-scrollbar mx-auto flex h-full w-screen flex-col overflow-auto"
      id="content-body"
    >
      <HeaderView />
      <main className="my-4 h-screen w-full ">
        <div className="mx-auto w-full max-w-[1200px]">{props.children}</div>
      </main>
      {
        !isDevMode() ? (
          <div className="flex gap-1 items-center fixed bottom-40px right-1 z-50">
            <a
              href="https://forms.gle/eX9Jb2eDMPuw6Hky9"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 px-[4px] py-[4px] rounded-[100px] shadow-lg border border-gray-100 cursor-pointer bg-white hover:bg-gray-25 group"
            >
              <span className="text-12 font-semibold group-hover:ml-2 hidden group-hover:block"
              >Give feedback</span>
              <img className="h-40px w-40px" src={logoApp} alt="Logo App" />
            </a>
          </div>
        ) : null
      }

    </div>
  )
}
