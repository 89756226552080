import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { signIn } from "src/features/auth"
import { RECEIVE_TOKEN_URL, STATE_RENDER_APP } from "src/constants/common"
import { ShareTokenTypes, isIFrame } from "./types"

// let isIframeError = true
function ShareToken(props: ShareTokenTypes) {
  const { setStateRenderView } = props
  const [isRender, setIsRender] = useState<boolean>(true)
  const dispatch = useDispatch()
  useEffect(() => {
    window.addEventListener("message", handleReceiveMessage)
    return () => window.removeEventListener("message", handleReceiveMessage)
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleReceiveMessage = (e: any) => {
    console.log("RECEIVE MESS", e?.data)
    if (e?.data?.type === "authorized") {
      const accessTokenData = e.data?.token?.accessToken
      const profileData = JSON.parse(e.data?.profile)
      let data = undefined
      if (accessTokenData && profileData?.id) {
        data = {
          user: profileData,
          access_token: accessTokenData,
        }

        localStorage.setItem("accessToken", accessTokenData)
        localStorage.setItem("userId", profileData?.id)
      }
      dispatch(signIn(data))
      setStateRenderView(STATE_RENDER_APP.APP)
    }
  }
  const handleOnLoad = () => {
    const frame = document.getElementById("socialLogin")
    if (isIFrame(frame) && frame.contentWindow) {
      console.log("POST MESSAGE")
      frame.contentWindow.postMessage({ type: "connect" }, "*")
    }
  }

  useEffect(() => {
    setIsRender(true)
  }, [])

  if (!isRender) return null
  return (
    <iframe
      id="socialLogin"
      style={{
        width: 0,
        height: 0,
        position: "absolute",
        left: 0,
        top: 0,
        display: "none",
        visibility: "hidden",
      }}
      width={0}
      height={0}
      title="socialLogin"
      src={RECEIVE_TOKEN_URL}
      onLoad={handleOnLoad}
    />
  )
}

export default ShareToken
