import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import type { RootState } from "src/app/store"
import ImgCustom from "../img-custom"
import avatarDefault from "src/assets/images/avatar-default.webp"
import { Badge } from "primereact/badge"
import { getLogoApp } from "src/utils/app-logo"

export default function HeaderView() {
  const authStore = useSelector((state: RootState) => state.auth)
  const countCart = useMemo(() => {
    return 0
  }, [])
  return (
    <div className="flex items-center justify-between bg-blue-600 px-1 md:px-4 py-1">
      <div className="flex items-center">
        <img className="w-[20px] h-[20px] md:h-26px md:w-[26px]" src={getLogoApp()} alt="appLogo" />
        <span className="ml-4px md:ml-[12px] text-[14px] md:text-[23px] font-bold leading-[18px] md:leading-[36px] text-white">
          {process.env.REACT_APP_TITLE}
        </span>
        <span className="ml-4px md:ml-[12px] text-[12px] md:text-[18px] font-medium leading-[16px] md:leading-[28px] text-white ">
          {"Marketing Hub"}
        </span>
      </div>
      <div className="flex items-center">
        <div className="flex items-center justify-center">
          <i className="pi pi-bell p-overlay-badge cursor-pointer text-[20px] md:text-24 text-white">
            {countCart ? (
              <Badge
                className="text-14"
                severity="danger"
                value={countCart}
              ></Badge>
            ) : null}
          </i>
        </div>
        <span className="ml-4px md:ml-3 text-[12px] md:text-[14px] font-medium leading-[16px] md:leading-[20px] text-white">
          {authStore?.value?.user?.full_name}
        </span>
        <div className="ml-[12px]">
          {!authStore?.value ? (
            <img
              alt="avatarDefault"
              src={avatarDefault}
              className="w-[24px] h-[24px] md:h-[32px] md:w-[32px] rounded-full object-cover object-center"
            />
          ) : (
            <>
              <div className="h-[32px] w-[32px] rounded-full border-[2px] border-orange-500">
                <ImgCustom
                  isAvatar={true}
                  alt="userAvatar"
                  url={authStore.value.user?.avatar_thumbnail_url as string}
                  className="rounded-full object-cover object-center"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
